import React from "react"
import { graphql } from "gatsby"
import { Seo, PageLayout } from "../components"
import { Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProfilePicture from "../components/ProfilePicture"

export default ({ data }) => {
  const { printStatement, firstName, lastName, occupation } =
    data.site.siteMetadata
  return (
    <PageLayout>
      <Container className="text-center pt-5 mt-5" fluid>
        <ProfilePicture />
        {printStatement && (
          <p className="mt-2">
            <b> You build it, you run it.</b>
          </p>
        )}
        <Container className="py-0 my-0">
          <h1
            style={{
              fontSize: "5rem",
              color: "black",
            }}
          >
            <span className="first-name">{firstName}</span>&nbsp;
            <span className="last-name">{lastName}</span>
          </h1>
          <p>
            <i>{occupation}</i>
          </p>
        </Container>
        <hr className="my-3 w-25" />
        <div className="d-md-inline-flex icons-container">
          <a
            href="https://github.com/lukas-weiss"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "github"]}
              className="icons github"
              title="Github"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/lweiss-5563371ab/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "linkedin"]}
              className="icons linkedin"
              title="LinkedIn"
            />
          </a>
          <a
            href="https://www.xing.com/profile/Lukas_Weiss12"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "xing"]}
              className="icons xing"
              title="Xing"
            />
          </a>
          {/*<a*/}
          {/*  href="https://www.freecodecamp.org"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={["fab", "free-code-camp"]}*/}
          {/*    className="icons fcc"*/}
          {/*    title="FreeCodeCamp"*/}
          {/*  />*/}
          {/*</a>*/}
          {/*<a*/}
          {/*  href="https://www.hackerrank.com/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={["fab", "hackerrank"]}*/}
          {/*    className="icons hr"*/}
          {/*    title="Hackerrank"*/}
          {/*  />*/}
          {/*</a>*/}
          {/* <a
            href="https://www.kaggle.com/lweiss89"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "kaggle"]}
              className="icons kaggle"
              title="Kaggle"
            />
          </a> */}
          <a
            href="mailto:info@l-weiss.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fas", "envelope"]}
              className="icons mail"
              title="e-mail"
            />
          </a>
        </div>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        printStatement
        firstName
        lastName
        occupation
      }
    }
  }
`

export const Head = () => {
  return <Seo title="Home" />
}
